<template>
    <div
        ref="jumpLinksVertical"
        class="jump-links-vertical-container"
    >
        <nav
            class="jump-links-vertical"
            :class="{ 'jump-links-vertical--background': turnOnBackground }"
        >
            <div
                v-if="title || translations.title"
                class="jump-links-vertical__heading u-heading-4"
            >
                {{ title || translations.title }}
            </div>

            <div class="jump-links-vertical__list-container">
                <ul class="jump-links-vertical__list">
                    <span
                        class="jump-links-vertical__list-bar"
                        :style="{ height: `${activeItemHeight}px`, top: `${activeItemPosition}px` }"
                    ></span>

                    <li class="jump-links-vertical__list-container">
                        <ul class="jump-links-vertical__list">
                            <li
                                v-for="item in restructuredItems.main"
                                :key="item.label"
                                class="jump-links-vertical__list-item"
                            >
                                <a
                                    :href="`#${item.id}`"
                                    class="jump-links-vertical__list-item-link u-regular"
                                    :class="{ 'jump-links-vertical__list-item--active u-bold': activeItemId === item.id }"
                                    @click.prevent="triggerScroll(`#${item.id}`, -70)"
                                >
                                    {{ item.label }}
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li
                        v-if="restructuredItems.secondary"
                        :class="[
                            'jump-links-vertical__list-container-collapsable',
                            { 'jump-links-vertical__list-container-collapsable--open': !collapsed, },
                            { 'jump-links-vertical__list-container-collapsable--open': !collapsable, },
                        ]"
                    >
                        <ul class="jump-links-vertical__list jump-links-vertical__collapsable-list">
                            <li
                                v-for="item in restructuredItems.secondary"
                                :key="item.label"
                                class="jump-links-vertical__list-item"
                            >
                                <a
                                    :href="`#${item.id}`"
                                    class="jump-links-vertical__list-item-link u-regular"
                                    :class="{ 'jump-links-vertical__list-item--active u-bold': activeItemId === item.id }"
                                    @click.prevent="triggerScroll(`#${item.id}`, -70)"
                                >
                                    {{ item.label }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div
                    v-if="collapsable && canBeCollapsed"
                    class="jump-links-vertical__list-item"
                >
                    <a
                        class="jump-links-vertical__toggle u-paragraph u-bold"
                        :class="{ 'jump-links-vertical__toggle--open': !collapsed }"
                        @click="collapsed = !collapsed;"
                    >
                        {{ collapsed ? translations.seeMore : translations.seeLess }}

                        <FontAwesomeIcon
                            class="jump-links-vertical__toggle-icon"
                            :icon="faChevronDown"
                        />
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useTableOfContents } from '../../../js/stores/table-of-contents';
import { storeToRefs } from 'pinia';
import { scrollToSelector } from '@utils/scroll-to-selector';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

defineProps({
    title: {
        type: String,
        required: false,
        default: null,
    },
    turnOnBackground: {
        type: Boolean,
        required: false,
        default: false,
    },
    background: {
        type: String,
        required: false,
        default: null,
    },
    collapsable: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const page = usePage();

const tableOfContents = useTableOfContents(`table-of-contents-${page.props.url}`);
const { headings: items } = storeToRefs(tableOfContents);

const translations = page.props.settings.translations?.jumpLinks || {};

const activeItem = ref(null);
const activeItemHeight = ref(0);
const activeItemPosition = ref(0);
const activeItemId = ref(items.value?.[0]?.id || '');
const jumpLinksVertical = ref(null);
const collapsed = ref(true);
const restructuredItems = ref({});
const canBeCollapsed = ref(false);

const triggerScroll = (selector, offset) => {
    scrollToSelector(selector, offset);
};

const handleActiveItem = () => {
    setTimeout(() => {
        activeItem.value = jumpLinksVertical.value.querySelector('.jump-links-vertical__list-item--active');
        if (activeItem.value) {
            activeItemHeight.value = activeItem.value.offsetHeight;
            activeItemPosition.value = activeItem.value.offsetTop;
        }
    }, 0);
};

let linkObserver = null;

const observeLinks = () => {
    const options = {
        threshold: 0,
        rootMargin: '0px 0px -70% 0px',
    };

    linkObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                activeItemId.value = entry.target.id;
                handleActiveItem();
            }
        });
    }, options);

    items.value.forEach(item => {
        const element = document.getElementById(item.id);

        if (element) {
            linkObserver.observe(element);
        }
    });
};

if (items.value.length > 5) {
    canBeCollapsed.value = true;

    restructuredItems.value = {
        main: {
            ...items.value.slice(0, 5),
        },
        secondary: {
            ...items.value.slice(5),
        },
    };
} else {
    restructuredItems.value = {
        main: {
            ...items.value,
        },
        secondary: [],
    };
}

onMounted(() => {
    nextTick(() => {
        observeLinks();
        handleActiveItem();
    });
});

onUnmounted(() => {
    linkObserver.disconnect();
});
</script>

<style lang="scss">
@use 'watson-organism-jump-links-vertical';
</style>

<style lang="scss" scoped>
@layer organism {
    .jump-links-vertical {
        --background: v-bind('background');
    }
}
</style>
