import { defineStore } from 'pinia';
import { ref } from 'vue';
import slug from 'slug';

export const useTableOfContents = (id = 1) => {
    return defineStore(`table-of-contents-${id}`, () => {
        const enabled = ref(false);
        const headings = ref([]);

        const findHeadings = content => {
            if (!Array.isArray(content)) return;

            headings.value = content.reduce((acc, item) => {
                if (item.blockName === 'core/heading' && (!item.attrs?.level || item.attrs.level === 2)) {
                    const label = item.innerHTML
                        .replace(/<[^>]*>|&nbsp;/g, '')
                        .trim()
                        .replace(/\s+/g, ' ');
                    const id = slug(label);
                    item.id = /^\d/.test(id) ? `h-${id}` : id;
                    acc.push({ id: item.id, label });
                }
                return acc;
            }, []);
        };

        return {
            enabled,
            headings,
            initializeHeadings: findHeadings,
        };
    })();
};
